import request from '@/axios/index';

//  用户信息
export function user(query) {
    return request({
        url: '/api/app/v1/mine/index',
        method: 'post',
        data: query
    })
}
//  修改信息
export function editUser(query) {
    return request({
        url: '/api/app/v1/mine/edit/info',
        method: 'post',
        data: query
    })
}
// 常见问题列表
export function problem(query) {
    return request({
        url: '/api/app/v1/mine/common/problem/list',
        method: 'post',
        data: query
    })
}
// 常见问题详情
export function problemdetail(query) {
    return request({
        url: '/api/app/v1/h5/common/problem/detail?commonProblemId=' + query,
        method: 'get',
    })
}
// 用户协议， 隐私政策，关于我们
export function agreement(query) {
    return request({
        url: '/api/app/v1/h5/agreement?typeName=' + query,
        method: 'get',
    })
}

// 常见问题详情
export function opinion(query) {
    return request({
        url: '/api/app/v1/mine/common/problem/detail',
        method: 'post',
        data: query
    })
}
//  获取课程协议
export function getAgressment(query) {
    return request({
        url: '/api/app/v1/h5/course/purchase/agreement?id=' + query,
        method: 'get',
    })
}
// 设置 start

// 注销
export function destroy(query) {
    return request({
        url: '/api/app/v1/mine/destroy',
        method: 'post',
        data: query
    })
}

// 验证码校验
export function mobileTest(query) {
    return request({
        url: '/api/frame/v1/customer/verification/code',
        method: 'post',
        data: query
    })
}
// 验证码校验
export function bangdi(query) {
    return request({
        url: '/api/frame/v1/customer/binding/mobile',
        method: 'post',
        data: query
    })
}
// 上传图片
export function uploadImage(query) {
    return request({
        url: '/api/pc/alioss/uploadImage',
        method: 'post',
        data: query
    })
}
// 意见反馈
export function feedbackadd(query) {
    return request({
        url: '/api/frame/v1/feedback/add',
        method: 'post',
        data: query
    })
}
// 获取积分明细
export function getPointList(query) {
    return request({
        url: '/api/app/v1/mine/point/list',
        method: 'post',
        data: query
    })
}

// 我的订单列表
export function orderList(query) {
    return request({
        url: '/api/app/v1/mine/order/list',
        method: 'post',
        data: query
    })
}
// 我的订单详情
export function getorderInfo(query) {
    return request({
        url: '/api/app/v1/mine/order/detail',
        method: 'post',
        data: query
    })
}


// 我的-我的消息   获取我的消息列表
export function notificationList(data) {
    return request({
        url: '/api/app/v1/mine/notification/list',
        method: 'post',
        data: data
    })
}
// 取消订单
export function cancalOrder(query) {
    return request({
        url: '/api/app/v1/mine/order/cancel',
        method: 'post',
        data: query
    })
}
//  继续支付
export function payOrder(query) {
    return request({
        url: '/api/h5/v1/course/order/course/package/continue/pay',
        method: 'post',
        data: query
    })
}
export function publicCode(query) {
    return request({
        url: '/api/app/v1/mine/get/public/code',
        method: 'post',
        data: query
    })
}
// 查询兑换
export function searchExchange(query) {
    return request({
        url: '/api/app/v1/mine/exchange/get/info',
        method: 'post',
        data: query
    })
}
// 进行兑换
export function exchange(query) {
    return request({
        url: '/api/app/v1/mine/exchange/do/exchange',
        method: 'post',
        data: query
    })
}





<template>
  <div class="feedback">
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" @click="gopath('back')" class="icon" />
      </div>
      <div class="title">意见反馈</div>
    </div>
    <div class="content">
      <div class="title">
        <span class="border"></span>
        <span>填写内容</span>
      </div>
      <van-field
        v-model="content"
        rows="2"
        autosize
        type="textarea"
        maxlength="100"
        show-word-limit
      />
      <van-uploader v-model="fileList" :after-read="afterRead" :max-count="3" />
      <van-button @click="submit" class="btn" type="info">保存提交</van-button>
    </div>
  </div>
</template>

<script>
import { uploadImage, feedbackadd } from "@/api/user.js";
export default {
  data() {
    return {
      content: "",
      fileList: [],
    };
  },
  created() {
    this.get;
  },
  methods: {
    gopath(val) {
      if (val == "back") {
        this.$router.go(-1);
      } else {
        this.$router.push({
          path: "/me/detail",
          query: { commonProblemId: val },
        });
      }
    },
    afterRead(file) {
      console.log(this.fileList);
    },
    submit() {
      if (!this.content) {
        this.$toast("请输入内容");
        return;
      }
      let formData = new FormData();
      this.fileList.map((item, index) => {
        formData.append("files", item.file);
      });
      let imglist = [];
      uploadImage(formData).then((res) => {
        imglist = res.data.data;
        let formData1 = new FormData();
        formData1.append("content", this.content);
        formData1.append("pics", imglist.join(","));
        feedbackadd(formData1).then((res) => {
          if (res.data.code == 0) {
            this.$toast("添加成功");
            this.content = "";
            this.fileList = [];
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 10px 10px;
  display: flex;
  .left {
    width: 10%;
    .icon {
      font-size: 24px;
      font-weight: 550;
      color: #141414;
    }
  }
  .title {
    width: 85%;
    text-align: center;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
  }
}
.content {
  padding: 20px 14px;
  .title {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141414;
    line-height: 23px;
    margin-bottom: 12px;
    .border {
      display: inline-block;
      width: 3px;
      height: 16px;
      background: linear-gradient(180deg, #5d7dff 0%, #ffffff 120%);
      border-radius: 1px;
      margin-right: 5px;
    }
  }
  .van-cell {
    background: #f8f8f8;
    height: 250px;
    margin-bottom: 12px;
    .van-field__body {
      height: 96%;
    }
    /deep/ .van-field__word-limit {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .btn {
    width: 100%;
    margin-top: 38%;
    border-radius: 5px; /*no*/
  }
}
</style>